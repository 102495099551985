/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { jsx, Flex, Divider } from "theme-ui"

import Avatar from "./avatar"
import Img from "./image"
import Tab from "./tab"
import Modal, { modalClose } from "./modal"
import ExternalLink from "./external-link"
import useCharacters from "../hooks/use-characters"
import useWeapons from "../hooks/use-weapons"

const gemAfter = [``,` Sliver`,` Fragment`,` Chunk`,` Gemstone`]
	, imgFolder = {
		gem: `ascension-material-gem`,
		boss: `ascension-material-boss`,
		specialty: `local-specialty`,
		exp: `item`,
		talent: `talent-material`,
		weekly: `talent-material-boss`,
		common: `common-enemy-drop`,
		crown: `item`,
		weapon: `ascension-material-weapon`,
		elite: `elite-enemy-drop`,
		ore: `item`,
	}
	, materialCost = {
		character: {
			gem: [0,1,9,9,6],
			boss: [0,0,0,46,0],
			specialty: [168,0,0,0,0],
			exp: [0,0,0,419,0],
			talent: [0,9,63,114,0],
			weekly: [0,0,0,0,18],
			common: [36,96,129,0,0],
			crown: [0,0,0,0,3],
		},
		weapon5: {
			weapon: [0,5,14,14,6],
			elite: [0,23,27,41,0],
			common: [15,23,27,0,0],
			ore: [0,0,907,0,0],
		},
		weapon4: {
			weapon: [0,3,9,9,4],
			elite: [0,15,18,27,0],
			common: [10,15,18,0,0],
			ore: [0,0,605,0,0],
		},
		weapon3: {
			weapon: [0,2,6,6,3],
			elite: [0,10,12,18,0],
			common: [6,10,12,0,0],
			ore: [0,0,399,0,0],
		},
		weapon2: {
			weapon: [0,1,4,1,0],
			elite: [0,6,8,0,0],
			common: [5,7,0,0,0],
			ore: [0,0,108,0,0],
		},
		weapon1: {
			weapon: [0,1,3,1,0],
			elite: [0,5,6,0,0],
			common: [3,5,0,0,0],
			ore: [0,0,72,0,0],
		},
	}

const Materials = ({t}) => {
	const characters = useCharacters()
		, weapons = useWeapons()
		, [avatar, setAvatar] = useState(characters[0].name)
		, [material, setMaterial] = useState()
		, data = characters.concat(weapons).find(e=>e.name==avatar)
		, noMaterialCost = !materialCost[ (isCharacter(avatar) ? `character` : `weapon`+ fetchStar(avatar)) ][material]
	useEffect(()=>{
		if(noMaterialCost && material)
			setMaterial(null)
	},[avatar])
	return (<>
		<Flex sx={{mb: 3,}}>
			<Modal
				id={`avatar`}
				label={<Avatar
					folder={isCharacter(avatar) ? "character" : "weapon"}
					name={avatar}
					star={fetchStar(avatar)}
				/>}
				sxLabel={{
					width: [`calc((100% / 4) - 9px)`],
					maxWidth: `106px`,
				}}
				sxContent={{
					p: 0,
					width: [`calc(100% - 2px)`,`640px`,`760px`],
				}}
				safety={false}
			>
				<ModalContent t={t} onChange={setAvatar} />
			</Modal>
			<div sx={{
				my: `auto`,
				ml: [3,`24px`],
			}}>
				<div sx={{
					mb: [1,1,2], fontSize: [3,3,4],
				}}>{t(avatar)}</div>
				<Flex>
					{isCharacter(avatar) &&
						<div sx={{ width: [`28px`, `32px`], mr: 1, }}>
							<Img
								folder="element"
								name={data.element}
							/>
						</div>
					}
					<div sx={{ width: [`28px`, `32px`], mr: 2, }}>
						<Img
							folder="item"
							name={`Northlander `+ (isCharacter(avatar) ? data.weapon : data.type) +` Billet`}
						/>
					</div>
					<ExternalLink
						sxProp={{
							mt: `auto`,
							fontSize: [1,1,2],
						}}
						href={`//wiki.hoyolab.com/m/genshin/entry/`+ data.hoyowiki}
					>HoYoWiki</ExternalLink>
				</Flex>
			</div>
		</Flex>
		{isCharacter(avatar) ? <>
			<Avatars
				name={[
					gemName(data.element) + gemAfter[1],
					data.material.boss,
					data.material.specialty,
					`Hero's Wit`,
				]}
				star={[2, 4, 1, 4]}
				group={[`gem`,`boss`,`specialty`,`exp`,]}
				onClick={setMaterial}
			/>
			<Avatars
				name={[
					data.material.talent,
					data.material.talentBoss,
					data.material.commonEnemy,
					`Crown of Insight`,
				]}
				star={[2, 5, 1, 5]}
				onClick={setMaterial}
				group={[`talent`,`weekly`,`common`,`crown`,]}
			/>
			<MaterialDetails
				material={material}
				name={
					material==`gem` ? data.element :
					material==`boss` ? data.material.boss :
					material==`specialty` ? data.material.specialty :
					material==`exp` ? `Hero's Wit` :
					material==`talent` ? data.material.talent :
					material==`weekly` ? data.material.talentBoss :
					material==`common` ? data.material.commonEnemy :
					material==`crown` ? `Crown of Insight` :
				``}
				costGroup="character"
				onClick={setAvatar}
				t={t}
			/>
		</> : <>
			<Avatars
				name={[
					data.material.weapon,
					data.material.eliteEnemy,
					data.material.commonEnemy,
					`Mystic Enhancement Ore`,
				]}
				star={[2, 2, 1, 3]}
				group={[`weapon`,`elite`,`common`,`ore`,]}
				onClick={setMaterial}
			/>
			<MaterialDetails
				material={material}
				name={
					material==`weapon` ? data.material.weapon :
					material==`elite` ? data.material.eliteEnemy :
					material==`common` ? data.material.commonEnemy :
					material==`ore` ? `Mystic Enhancement Ore` :
				``}
				costGroup={`weapon`+ fetchStar(avatar)}
				onClick={setAvatar}
				t={t}
			/>
		</> }
	</>)
}

export default Materials

const ModalContent = ({t, onChange}) => {
	const characters = useCharacters().filter(e=>e.material != null)
		, weapons = useWeapons().filter(e=>e.material != null)
	return (
		<Tab
			name = "avatar"
			labels = {[ t(`Characters`), t(`Weapons`) ]}
			safety = {false}
			sxLabel = {{py: 2,}}
			sxContent = {{mx: 1, mb: 1, overflowY: `auto`, maxHeight: `70vh`,}}
		>
			<Flex sx={{flexWrap: `wrap`,}}>
				{characters.map((e,i)=>(<React.Fragment key={i}>
					<label sx={{
						width: [`calc(100% / 6)`, `calc(100% / 7)`, `calc(100% / 8)`],
						p: `4px`,
					}}>
						<Avatar
							onClick={()=> {
								modalClose()
								onChange(e.name)
							}}
							folder="character"
							name={e.name}
							star={fetchStar(e.name)}
						/>
					</label>
				</React.Fragment>))}
			</Flex>
			<Flex sx={{flexWrap: `wrap`,}}>
				{weapons.map((e,i)=>(<React.Fragment key={i}>
					<label sx={{
						width: [`calc(100% / 6)`, `calc(100% / 7)`, `calc(100% / 8)`],
						p: `4px`,
					}}>
						<Avatar
							onClick={()=> {
								modalClose()
								onChange(e.name)
							}}
							folder="weapon"
							name={e.name}
							star={fetchStar(e.name)}
						/>
					</label>
				</React.Fragment>))}
			</Flex>
		</Tab>
	)
}

const Avatars = ({name, star, onClick, group}) => (
	<Flex sx={{mx: [`-6px`],}}>
		{name.map((e,i)=>(<React.Fragment key={i}>
			<label sx={{
				width: `calc(100% / `+ name.length +`)`,
				p: [`6px`],
			}}>
				<Avatar
					folder={imgFolder[group[i]]}
					name={ (group[i]==`talent` || group[i]==`common` || group[i]==`weapon` || group[i]==`elite`) ? e + star[i] : e }
					star={star[i]}
					onClick={() => onClick(group[i])}
				/>
			</label>
		</React.Fragment>))}
	</Flex>
)

const MaterialDetails = ({
	material, name, costGroup, onClick, t
}) => (<>
	{material && <>
		<Divider />
		<Cost t={t}
			name={material==`gem` ? gemName(name) : name}
			costGroup={costGroup}
			group={material}
		/>
		<Divider />
		<CharactersFilter
			type={material}
			value={name}
			onClick={onClick}
		/>
		{material==`common` && <Divider />}
		<WeaponsFilter
			type={material}
			value={name}
			onClick={onClick}
		/>
	</> }
</>)

const Cost = ({name, group, costGroup, t}) => (<>
	<div sx={{textAlign: `center`,}}>{t(name)}</div>
	<Flex sx={{
		mx: `-4px`,
		justifyContent: `center`,
		flexDirection: `row-reverse`,
	}}>
		<Mora group={group} costGroup={costGroup} t={t} />
		{materialCost[costGroup][group] && materialCost[costGroup][group].map((e,i)=>(<React.Fragment key={i}>
			{e>0 && <div sx={{
				width: `calc(100% / 6)`,
				p: [`4px`],
			}}>
				<Avatar
					folder={imgFolder[group]}
					name={ group==`gem` ? name + gemAfter[i] :
						(group==`talent` || group==`common` || group==`weapon` || group==`elite`) ? name + (i+1) : name }
					star={i+1}
					text={e}
				/>
			</div> }
		</React.Fragment>))}
	</Flex>
</>)

const Mora = ({group, costGroup, t}) => {
	const mora = {
		character: {
			exp: 2092530,
			crown: 4957500,
		},
		weapon5: { ore: 1131445 },
		weapon4: { ore: 754265 },
		weapon3: { ore: 503820 },
		weapon2: { ore: 142695 },
		weapon1: { ore: 91802 },
	}
	return (<>
		{mora[costGroup][group] && 
			<div sx={{ width: `calc(100% / 6)`, p: [`4px`], }}>
				<Avatar
					folder="item" name="Mora" star={3}
					text={t(mora[costGroup][group])}
				/>
			</div>
		}
	</>)
}

const CharactersFilter = ({type, value, onClick}) => {
	const data = useCharacters()
		, characters = data.filter(e => {
			if(e.material == null)
				return false
			if(type == `gem`)
				return e.element == value
			if(type == `boss`)
				return e.material.boss == value
			if(type == `specialty`)
				return e.material.specialty == value
			if(type == `talent`)
				return e.material.talent == value
			if(type == `weekly`)
				return e.material.talentBoss == value
			if(type == `common`)
				return e.material.commonEnemy == value
			return false
		})
	return (
		<Flex sx={{flexWrap: `wrap`, mx: `-4px`,}}>
			{characters.map((e,i)=>(<React.Fragment key={i}>
				<label sx={{
					width: `calc(100% / 6)`,
					p: `4px`,
				}}>
					<Avatar
						folder="character"
						name={e.name}
						star={fetchStar(e.name)}
						onClick={() => onClick(e.name)}
					/>
				</label>
			</React.Fragment>))}
		</Flex>
	)
}

const WeaponsFilter = ({type, value, onClick}) => {
	const data = useWeapons()
		, weapons = data.filter(e => {
			if(e.material == null)
				return false
			if(type == `weapon`)
				return e.material.weapon == value
			if(type == `elite`)
				return e.material.eliteEnemy == value
			if(type == `common`)
				return e.material.commonEnemy == value
			return false
		})
	return (
		<Flex sx={{flexWrap: `wrap`, mx: `-4px`,}}>
			{weapons.map((e,i)=>(<React.Fragment key={i}>
				<label sx={{
					width: `calc(100% / 6)`,
					p: `4px`,
				}}>
					<Avatar
						folder="weapon"
						name={e.name}
						star={fetchStar(e.name)}
						onClick={() => onClick(e.name)}
					/>
				</label>
			</React.Fragment>))}
		</Flex>
	)
}

const isCharacter = name => {
	const characters = useCharacters()
	return characters.some(e=>e.name == name)
}

const fetchStar = name => {
	const data = useCharacters().concat(useWeapons())
	return data.find(e2=>e2.name == name) ? data.find(e2=>e2.name == name).star : 1
}

const gemName = element => {
	const names = {
		Pyro: `Agnidus Agate`,
		Cryo: `Shivada Jade`,
		Hydro: `Varunada Lazurite`,
		Electro: `Vajrada Amethyst`,
		Anemo: `Vayuda Turquoise`,
		Geo: `Prithiva Topaz`,
		Dendro: `Nagadus Emerald`,
	}
	return names[element]
}
