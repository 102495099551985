
const UseLocalStorage = class {
	constructor(key) {
		this._key = key
	}
	get(sub) {
		const data = isWindow() ? JSON.parse( localStorage.getItem( this._key ) ) : null
		if(sub) return data ? data[sub] : null
		else return data
	}
	set(sub, subArr, copy) {
		const old = this.get()
		let next = {}
		if(old == null) {
			next[sub] = subArr
		} else {
			next = {...old}
			if(copy === true) next[sub] = { ...old[sub], ...subArr }
			else next[sub] = subArr
		}
		if(isWindow())
			localStorage.setItem( this._key, JSON.stringify(next) )
	}
	remove(sub) {
		if(sub && isWindow()) localStorage.removeItem( this._key )
		else {
			const next = this.get()
			next[sub] = null
			if(isWindow())
				localStorage.setItem( this._key, JSON.stringify(next) )
		}
	}
}

export default UseLocalStorage

const isWindow = () => typeof window !== `undefined`
