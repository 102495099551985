import { graphql, useStaticQuery } from "gatsby"

type Nodes = {
	nodes: {
		name: string
		type: string
		star: number
		material: {
			weapon: string
			eliteEnemy: string
			commonEnemy: string
		}
		hoyowiki: number
	}[]
}
type UseWeaponsProps = {
	allWeaponSwordJson: {
		Nodes
	}
	allWeaponClaymoreJson: {
		Nodes
	}
	allWeaponPolearmJson: {
		Nodes
	}
	allWeaponCatalystJson: {
		Nodes
	}
	allWeaponBowJson: {
		Nodes
	}
}

const useWeapons = () => {
	const data = useStaticQuery<UseWeaponsProps>(graphql`{
		allWeaponSwordJson {
			nodes {
				name
				type
				star
				material {
					weapon
					eliteEnemy
					commonEnemy
				}
				hoyowiki
			}
		}
		allWeaponClaymoreJson {
			nodes {
				name
				type
				star
				material {
					weapon
					eliteEnemy
					commonEnemy
				}
				hoyowiki
			}
		}
		allWeaponPolearmJson {
			nodes {
				name
				type
				star
				material {
					weapon
					eliteEnemy
					commonEnemy
				}
				hoyowiki
			}
		}
		allWeaponCatalystJson {
			nodes {
				name
				type
				star
				material {
					weapon
					eliteEnemy
					commonEnemy
				}
				hoyowiki
			}
		}
		allWeaponBowJson {
			nodes {
				name
				type
				star
				material {
					weapon
					eliteEnemy
					commonEnemy
				}
				hoyowiki
			}
		}
	}`)
	return (
		data.allWeaponSwordJson.nodes.concat(
			data.allWeaponClaymoreJson.nodes.concat(
				data.allWeaponPolearmJson.nodes.concat(
					data.allWeaponCatalystJson.nodes.concat(
						data.allWeaponBowJson.nodes
					)
				)
			)
		)
	)
}

export default useWeapons

export const useWeaponStar = (name) => {
	const weapon = useWeapons().find(e => e.name == name)
	return weapon ? weapon.star : 0
}
