/** @jsx jsx */
import * as React from "react"
import { jsx, Flex } from "theme-ui"
import { Link } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import Svg from "./svg"
import { FaFont } from "./fa"

type NavProps = {
	links: {
		image: string
		text: string
		to: string
		sub?: {
			image: string
			text: string
			to: string
		}
	}[]
}

const Nav = ({links}: NavProps) => (
	<nav sx={{ display: `flex`, flexWrap: `wrap`, }}>
		{links.map((link,i) => (<React.Fragment key={i}>
			<div sx={{
				width: `calc(100% / 3)`,
				textAlign: `center`,
				mb: [`40px`,`40px`,`56px`],
			}}>
				<Link to={link.to}>
					<div sx={{
						maxWidth: `128px`,
						px: 3,
						mx: `auto`,
						mb: `12px`,
					}}>
						<Svg i={link.image} />
					</div>
					<div sx={{
						fontSize: 2,
						color: `text`,
					}}>{link.text}</div>
				</Link>
				{link.sub && <Flex sx={{
					mt: 3, mx: `auto`,
					width: `60%`,
					maxWidth: `calc(128px * 0.6)`,
				}}>
					<FaFont i="angle-right" sxProp={{
						fontSize: `1.2em`,
					}} />
					<Link
						to={link.sub.to}
						aria-label={link.sub.image}
						sx={{
							width: `100%`,
							pl: 1,
						}}
					>
						<Svg i={link.sub.image} />
					</Link>
				</Flex>}
			</div>
		</React.Fragment>))}
	</nav>
)

export default Nav
