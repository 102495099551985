/** @jsx jsx */
import { useEffect } from "react"
import { jsx, Flex } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import { FaFont } from "../components/fa"
import ExternalLink from "../components/external-link"
import Nav from "../components/@index-nav"
import Materials from "../components/@index-materials"
import UseLocalStorage from "../hooks/use-local-storage"

const Page = () => {
	const {t} = useTranslation()
	useEffect(()=>{
		const url = window.location
			, key = (new URLSearchParams(url.search)).get(`key`)
		if(key) {
			const storage = new UseLocalStorage(`auth`)
			storage.set(`shared`, key)
			window.history.replaceState(null, null, url.pathname)
		}
	},[])
	return (
		<Layout title={t(`Genshin Impact Online Tools`)} seoTitle="non">
			<Columns mb={2}>
				<div>
					<Nav links={[
						{ image: `atk-crit`, text: t(`Check Ideal`), to: `/atk-crit`, sub:{
							image: `avg-dmg`, to: `/avg-dmg`
						} },
						{ image: `team`, text: t(`Team Builder`), to: `/team-builder`, sub:{
							image: `dice`, to: `/random-team-generator`
						} },
						{ image: `character`, text: t(`Showcase`), to: `/showcase`, sub:{
							image: `analytics`, to: `/showcase-analytics`
						} },
						{ image: `book`, text: t(`Talent Materials`), to: `/talent-materials` },
						{ image: `wish`, text: t(`Wish Banner`), to: `/wish-banner` },
						{ image: `player-card`, text: t(`Player Card`), to: `/player-card-templates` },
					]} />
				</div>
				<div>
					<Materials t={t} />
				</div>
			</Columns>
			<Flex sx={{
				flexDirection: `column`,
				alignItems: `center`,
			}}>
				<div>{t(`Paimon Lab's Discord is here.`)}</div>
				<ExternalLink
					href="https://discord.gg/5bKYuCcmfu"
					sxProp={{
						display: `flex`,
						alignItems: `center`,
					}}
				>
					<FaFont i="discord" sxProp={{mr: `.5em`}} />
					{t(`Invite Link`)}
				</ExternalLink>
			</Flex>
		</Layout>
	)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index", "items", "characters", "weapons"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
